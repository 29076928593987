import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-4 flex items-center justify-between text-sm text-gray-400 dark:text-gray-600" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2" }

import {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/abstract-provider';
import { reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useConfig from '@/composables/useConfig';
import useEthers from '@/composables/useEthers';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { dateTimeLabelFor } from '@/composables/useTime';
import useTransactions from '@/composables/useTransactions';
import { balancerContractsService } from '@/services/balancer/contracts/balancer-contracts.service';
import useWeb3 from '@/services/web3/useWeb3';
import { TokenInfo } from '@/types/TokenList';
import { TransactionActionInfo } from '@/types/transactions';

/**
 * TYPES
 */
type Props = {
  lockablePoolTokenInfo: TokenInfo;
  totalLpTokens: string;
};

type UnlockActionState = {
  init: boolean;
  confirming: boolean;
  confirmed: boolean;
  confirmedAt: string;
  receipt?: TransactionReceipt;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    lockablePoolTokenInfo: { type: null, required: true },
    totalLpTokens: { type: String, required: true }
  } as unknown as undefined,
  emits: ["success", "close"] as unknown as undefined,
  setup(__props: {
  lockablePoolTokenInfo: TokenInfo;
  totalLpTokens: string;
}, { emit }: { emit: ({
  (e: 'success', value: UnlockActionState): void;
  (e: 'close'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const unlockActionState = reactive<UnlockActionState>({
  init: false,
  confirming: false,
  confirmed: false,
  confirmedAt: '',
});

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { networkConfig } = useConfig();
const { getProvider, explorerLinks } = useWeb3();
const { addTransaction } = useTransactions();
const { txListener, getTxConfirmedAt } = useEthers();

const { fNum2 } = useNumbers();

const unlockAction: TransactionActionInfo = {
  label: t(`unlockVeBAL.previewModal.actions.unlock.label`),
  loadingLabel: t(`unlockVeBAL.previewModal.actions.unlock.loadingLabel`),
  confirmingLabel: t(`unlockVeBAL.previewModal.actions.unlock.confirming`),
  action: submit,
  stepTooltip: t(`unlockVeBAL.previewModal.actions.unlock.tooltip`),
};

const actions = ref<TransactionActionInfo[]>([unlockAction]);

/**
 * METHODS
 */
async function handleTransaction(tx: TransactionResponse): Promise<void> {
  addTransaction({
    id: tx.hash,
    type: 'tx',
    action: 'unlock',
    summary: `${fNum2(props.totalLpTokens, FNumFormats.token)} ${
      props.lockablePoolTokenInfo.symbol
    }`,
    details: {
      totalLpTokens: props.totalLpTokens,
    },
  });

  unlockActionState.confirmed = await txListener(tx, {
    onTxConfirmed: async (receipt: TransactionReceipt) => {
      emit('success', unlockActionState);

      unlockActionState.confirming = false;
      unlockActionState.receipt = receipt;

      const confirmedAt = await getTxConfirmedAt(receipt);
      unlockActionState.confirmedAt = dateTimeLabelFor(confirmedAt);
    },
    onTxFailed: () => {
      unlockActionState.confirming = false;
    },
  });
}

async function submit() {
  try {
    let tx: TransactionResponse;
    unlockActionState.init = true;

    tx = await balancerContractsService.veBAL.unlock(getProvider());

    unlockActionState.init = false;
    unlockActionState.confirming = true;

    console.log('Receipt', tx);

    handleTransaction(tx);
    return tx;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalActionSteps = _resolveComponent("BalActionSteps")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_unref(unlockActionState).confirmed)
      ? (_openBlock(), _createBlock(_component_BalActionSteps, {
          key: 0,
          actions: actions.value,
          disabled: _ctx.disabled
        }, null, 8, ["actions", "disabled"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BalIcon, { name: "clock" }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(unlockActionState).confirmedAt), 1)
            ]),
            (_unref(unlockActionState).receipt)
              ? (_openBlock(), _createBlock(_component_BalLink, {
                  key: 0,
                  href: 
            _unref(explorerLinks).txLink(_unref(unlockActionState).receipt.transactionHash)
          ,
                  external: "",
                  noStyle: "",
                  class: "group flex items-center"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(networkConfig).explorerName) + " ", 1),
                    _createVNode(_component_BalIcon, {
                      name: "arrow-up-right",
                      size: "sm",
                      class: "ml-px transition-colors group-hover:text-pink-500"
                    })
                  ]),
                  _: 1
                }, 8, ["href"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_BalBtn, {
            color: "gray",
            outline: "",
            block: "",
            class: "mt-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('unlockVeBAL.previewModal.returnToVeBalPage')), 1)
            ]),
            _: 1
          })
        ], 64))
  ]))
}
}

})