import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "bg-gray-50 py-16 dark:bg-gray-900 xl:py-20" }
const _hoisted_3 = {
  key: 0,
  class: "lg:container lg:mx-auto"
}
const _hoisted_4 = { class: "px-4" }
const _hoisted_5 = {
  key: 1,
  class: "pt-16 xl:container xl:mx-auto xl:px-4 xl:pt-20"
}
const _hoisted_6 = { class: "mb-16 xl:px-0" }
const _hoisted_7 = {
  key: 2,
  class: "text-center"
}
const _hoisted_8 = { class: "text-lg font-semibold" }

import Hero from '@/components/contextual/pages/vebal/Hero.vue';
import LMVoting from '@/components/contextual/pages/vebal/LMVoting/LMVoting.vue';
import MyVeBAL from '@/components/contextual/pages/vebal/MyVeBAL/MyVeBAL.vue';
import { isVeBalSupported } from '@/composables/useVeBAL';

export default _defineComponent({
  setup(__props) {

/**
 * veBAL page
 */

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isVeBalSupported))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(Hero)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_unref(isVeBalSupported))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(MyVeBAL)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_unref(isVeBalSupported))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(LMVoting)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('veBAL.notSupported.title')), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('veBAL.notSupported.description')), 1)
        ]))
  ], 64))
}
}

})