import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useVeBalLockInfoQuery from '@/composables/queries/useVeBalLockInfoQuery';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';
import { Pool } from '@/services/pool/types';
import { TokenInfo } from '@/types/TokenList';

import UnlockActions from './components/UnlockActions.vue';
import UnlockAmount from './components/UnlockAmount.vue';
import UnlockSummary from './components/UnlockSummary.vue';

/**
 * TYPES
 */
type Props = {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo: VeBalLockInfo;
  totalLpTokens: string;
  fiatTotalLpTokens: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    veBalLockInfo: { type: null, required: true },
    totalLpTokens: { type: String, required: true },
    fiatTotalLpTokens: { type: String, required: true }
  } as unknown as undefined,
  emits: ["close"] as unknown as undefined,
  setup(__props: {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo: VeBalLockInfo;
  totalLpTokens: string;
  fiatTotalLpTokens: string;
}, { emit }: { emit: ({
  (e: 'close'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * COMPOSABLES
 */
const { refetch: refetchLockInfo } = useVeBalLockInfoQuery();

/**
 * STATE
 */
const unlockConfirmed = ref(false);
const lockablePool = ref(props.lockablePool);
const lockablePoolTokenInfo = ref(props.lockablePoolTokenInfo);
const veBalLockInfo = ref(props.veBalLockInfo);
const totalLpTokens = ref(props.totalLpTokens);
const fiatTotalLpTokens = ref(props.fiatTotalLpTokens);

/**
 * COMPOSABLES
 */
const { t } = useI18n();

/**
 * COMPUTED
 */
const title = computed(() => {
  return unlockConfirmed.value
    ? t(`unlockVeBAL.previewModal.titles.unlock.confirmed`)
    : t(`unlockVeBAL.previewModal.titles.unlock.default`);
});

/**
 * METHODS
 */
function handleClose() {
  emit('close');
}

function handleSuccess() {
  unlockConfirmed.value = true;
  refetchLockInfo.value();
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    fireworks: unlockConfirmed.value,
    onClose: handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (unlockConfirmed.value)
          ? (_openBlock(), _createBlock(_component_BalCircle, {
              key: 0,
              size: "8",
              color: "green",
              class: "mr-2 text-white"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalIcon, { name: "check" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, _toDisplayString(_unref(title)), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(UnlockAmount, {
        lockablePool: lockablePool.value,
        totalLpTokens: totalLpTokens.value
      }, null, 8, ["lockablePool", "totalLpTokens"]),
      _createVNode(UnlockSummary, {
        fiatTotalLpTokens: fiatTotalLpTokens.value,
        veBalLockInfo: veBalLockInfo.value
      }, null, 8, ["fiatTotalLpTokens", "veBalLockInfo"]),
      _createVNode(UnlockActions, {
        lockablePool: lockablePool.value,
        lockablePoolTokenInfo: lockablePoolTokenInfo.value,
        totalLpTokens: totalLpTokens.value,
        veBalLockInfo: veBalLockInfo.value,
        class: "mt-4",
        onSuccess: handleSuccess,
        onClose: handleClose
      }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "totalLpTokens", "veBalLockInfo"])
    ]),
    _: 1
  }, 8, ["fireworks"]))
}
}

})