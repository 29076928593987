import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4" }

import { computed } from 'vue';

import { useLock } from '@/composables/useLock';
import useWeb3 from '@/services/web3/useWeb3';

import MyVeBalCards from './components/MyVeBalCards.vue';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const {
  isLoadingLockPool,
  isLoadingLockInfo,
  lockPool,
  lockPoolToken,
  lock,
  lockedFiatTotal,
} = useLock();
const { isWalletReady } = useWeb3();

/**
 * COMPUTED
 */

const isLoading = computed(() =>
  isWalletReady.value
    ? isLoadingLockPool.value || isLoadingLockInfo.value
    : false
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('veBAL.myVeBAL.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_unref(isLoading))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (n) => {
            return _createVNode(_component_BalLoadingBlock, {
              key: n,
              class: "h-24"
            })
          }), 64))
        : (_openBlock(), _createBlock(MyVeBalCards, {
            key: 1,
            veBalLockInfo: _unref(lock),
            lockablePool: _unref(lockPool),
            lockablePoolTokenInfo: _unref(lockPoolToken),
            lockedFiatTotal: _unref(lockedFiatTotal)
          }, null, 8, ["veBalLockInfo", "lockablePool", "lockablePoolTokenInfo", "lockedFiatTotal"]))
    ])
  ], 64))
}
}

})