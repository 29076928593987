import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-2 text-sm font-semibold" }
const _hoisted_2 = { class: "text-xs font-normal" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "mb-2" }

import { computed } from 'vue';

import useNumbers from '@/composables/useNumbers';
import { bnum, scale } from '@/lib/utils';
import { VotingGaugeWithVotes } from '@/services/balancer/gauges/gauge-controller.decorator';

/**
 * TYPES
 */
type Props = {
  gauge: VotingGaugeWithVotes;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    gauge: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  gauge: VotingGaugeWithVotes;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */

const votesThisPeriod = computed<string>(() =>
  formatVotesAsPercent(props.gauge.votes)
);

const votesNextPeriod = computed<string>(() =>
  formatVotesAsPercent(props.gauge.votesNextPeriod)
);

const voteDifference = computed<number>(() => {
  return Number(props.gauge.votesNextPeriod) - Number(props.gauge.votes);
});

const voteDifferenceText = computed<string>(() => {
  const text = formatVotesAsPercent(voteDifference.value.toString());
  const prefix = voteDifference.value > 0 ? '+' : '';
  return `${prefix}${text}`;
});

const voteTextClass = computed(() => {
  return {
    'text-green-600': voteDifference.value > 0,
    'text-red-600': voteDifference.value < 0,
  };
});

/**
 * METHODS
 */
function formatVotesAsPercent(votes: string): string {
  const normalizedVotes = scale(bnum(votes), -18);
  return fNum2(normalizedVotes.toString(), {
    style: 'percent',
    maximumFractionDigits: 2,
    fixedFormat: true,
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createBlock(_component_BalTooltip, { textAlign: "left" }, {
    activator: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_unref(voteTextClass))
      }, _toDisplayString(_unref(votesNextPeriod)), 3)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('veBAL.liquidityMining.votesTooltip.title')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('veBAL.liquidityMining.votesTooltip.thisPeriod', [
              _unref(votesThisPeriod),
            ])), 1),
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.$t('veBAL.liquidityMining.votesTooltip.nextPeriod', [
              _unref(votesNextPeriod),
            ])) + " ", 1),
            _createElementVNode("span", {
              class: _normalizeClass(_unref(voteTextClass))
            }, _toDisplayString(_unref(voteDifferenceText)), 3)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})