import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6c351ae9")
const _hoisted_1 = { class: "label font-medium" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "truncate font-semibold" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "secondary-value text-secondary font-medium" }
_popScopeId()

import { differenceInDays, format } from 'date-fns';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import UnlockPreviewModal from '@/components/forms/lock_actions/UnlockForm/components/UnlockPreviewModal/UnlockPreviewModal.vue';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import useVeBal from '@/composables/useVeBAL';
import { bnum } from '@/lib/utils';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';
import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
  lockedFiatTotal: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    veBalLockInfo: { type: null, required: false },
    lockedFiatTotal: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
  lockedFiatTotal: string;
}) {

const props = __props


/**
 * STATE
 */
const showUnlockPreviewModal = ref(false);

/**
 * COMPOSABLES
 */
const { balanceFor } = useTokens();
const { fNum2 } = useNumbers();
const { veBalBalance, lockablePoolId } = useVeBal();
const { t } = useI18n();
const { isWalletReady } = useWeb3();

/**
 * COMPUTED
 */
const poolShares = computed(() =>
  bnum(props.lockablePool.totalLiquidity).div(props.lockablePool.totalShares)
);

const bptBalance = computed(() => balanceFor(props.lockablePool.address));

const fiatTotal = computed(() =>
  poolShares.value.times(bptBalance.value).toString()
);

const lockedUntil = computed(() => {
  if (props.veBalLockInfo?.hasExistingLock && !props.veBalLockInfo?.isExpired) {
    return format(props.veBalLockInfo.lockedEndDate, PRETTY_DATE_FORMAT);
  }

  return '—';
});

const totalExpiredLpTokens = computed(() =>
  props.veBalLockInfo?.isExpired ? props.veBalLockInfo.lockedAmount : '0'
);

const fiatTotalExpiredLpTokens = computed(() =>
  bnum(props.lockablePool.totalLiquidity)
    .div(props.lockablePool.totalShares)
    .times(totalExpiredLpTokens.value)
);

const cards = computed(() => {
  const hasExistingLock = props.veBalLockInfo?.hasExistingLock;
  const isExpired = props.veBalLockInfo?.isExpired;

  return [
    {
      id: 'myLpToken',
      label: t('veBAL.myVeBAL.cards.myLpToken.label'),
      value: isWalletReady.value
        ? fNum2(fiatTotal.value, FNumFormats.fiat)
        : '—',
      secondaryText: isWalletReady.value
        ? fNum2(bptBalance.value, FNumFormats.token)
        : '—',
      showPlusIcon: isWalletReady.value ? true : false,
      plusIconTo: {
        name: 'invest',
        params: { id: lockablePoolId.value },
        query: { returnRoute: 'vebal' },
      },
    },
    {
      id: 'myLockedLpToken',
      label: t('veBAL.myVeBAL.cards.myLockedLpToken.label'),
      value: isWalletReady.value
        ? fNum2(props.lockedFiatTotal, FNumFormats.fiat)
        : '—',
      secondaryText: isWalletReady.value
        ? fNum2(props.veBalLockInfo?.lockedAmount ?? '0', FNumFormats.token)
        : '—',
      showPlusIcon: isWalletReady.value && !isExpired ? true : false,
      plusIconTo: { name: 'get-vebal', query: { returnRoute: 'vebal' } },
      showUnlockIcon: isExpired ? true : false,
    },
    {
      id: 'lockedEndDate',
      label: t('veBAL.myVeBAL.cards.lockedEndDate.label'),
      value: lockedUntil.value,
      secondaryText:
        hasExistingLock && !isExpired
          ? t('veBAL.myVeBAL.cards.lockedEndDate.secondaryText', [
              differenceInDays(new Date(lockedUntil.value), new Date()),
            ])
          : '-',
      showPlusIcon: hasExistingLock && !isExpired ? true : false,
      plusIconTo: { name: 'get-vebal', query: { returnRoute: 'vebal' } },
    },
    {
      id: 'myVeBAL',
      label: t('veBAL.myVeBAL.cards.myVeBAL.label'),
      secondaryText:
        hasExistingLock && !isExpired
          ? t('veBAL.myVeBAL.cards.myVeBAL.secondaryText', [
              fNum2(
                bnum(veBalBalance.value)
                  .div(props.veBalLockInfo.totalSupply)
                  .toString(),
                {
                  style: 'percent',
                  maximumFractionDigits: 4,
                }
              ),
            ])
          : '-',
      showPlusIcon: false,
      value: hasExistingLock
        ? fNum2(veBalBalance.value, FNumFormats.token)
        : '—',
    },
  ];
});

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cards), (card) => {
      return (_openBlock(), _createBlock(_component_BalCard, {
        key: card.id
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(card.label), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["value", card.id])
          }, [
            (card.id === 'myLockedLpToken')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", {
                    class: _normalizeClass([{ 'text-red-500': _unref(bnum)(_unref(totalExpiredLpTokens)).gt(0) }, "mr-1 truncate font-semibold"])
                  }, _toDisplayString(card.value), 3),
                  (_unref(bnum)(_unref(totalExpiredLpTokens)).gt(0))
                    ? (_openBlock(), _createBlock(_component_BalTooltip, {
                        key: 0,
                        text: _ctx.$t('veBAL.myVeBAL.cards.myExpiredLockTooltip'),
                        iconSize: "sm",
                        iconName: 'alert-triangle',
                        iconClass: 'text-red-500 hover:text-red-700 dark:hover:text-red-400 transition-colors',
                        width: "72",
                        class: "relative top-0.5"
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(card.value), 1)
                ])),
            _createElementVNode("div", _hoisted_5, [
              (card.showUnlockIcon)
                ? (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 0,
                    name: "minus-circle",
                    class: "minus-circle mr-2 cursor-pointer transition-all",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showUnlockPreviewModal.value = true))
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (card.showPlusIcon)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: card.plusIconTo,
                      class: "flex items-center text-blue-600 dark:text-blue-400"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BalIcon, {
                          name: "plus-circle",
                          class: "plus-circle cursor-pointer transition-all"
                        })
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2),
          _createElementVNode("div", _hoisted_6, _toDisplayString(card.secondaryText), 1)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showUnlockPreviewModal.value)
        ? (_openBlock(), _createBlock(UnlockPreviewModal, {
            key: 0,
            lockablePool: __props.lockablePool,
            lockablePoolTokenInfo: __props.lockablePoolTokenInfo,
            veBalLockInfo: __props.veBalLockInfo,
            totalLpTokens: _unref(totalExpiredLpTokens),
            fiatTotalLpTokens: _unref(fiatTotalExpiredLpTokens),
            onClose: _cache[1] || (_cache[1] = ($event: any) => (showUnlockPreviewModal.value = false))
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "veBalLockInfo", "totalLpTokens", "fiatTotalLpTokens"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}
}

})