import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

import { computed } from 'vue';

/**
 * TYPES
 */
type Props = {
  isGaugeExpired: boolean;
  hasUserVotes: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    isGaugeExpired: { type: Boolean, required: true },
    hasUserVotes: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  isGaugeExpired: boolean;
  hasUserVotes: boolean;
}) {

const props = __props


/**
 * COMPUTED
 */
const showRemoveVotes = computed(() => {
  return props.isGaugeExpired && props.hasUserVotes;
});

const disabled = computed(() => {
  return props.isGaugeExpired && !props.hasUserVotes;
});

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_component_BalBtn, {
    color: _unref(showRemoveVotes) ? 'red' : 'blue',
    disabled: _unref(disabled),
    class: _normalizeClass(`vote-btn
      ${_unref(showRemoveVotes) ? 'vote-btn--red' : 'vote-btn--blue'}`),
    outline: "",
    size: "sm",
    flat: "",
    block: ""
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(showRemoveVotes)
        ? _ctx.$t('veBAL.liquidityMining.table.remove')
        : _ctx.$t('veBAL.liquidityMining.table.vote')), 1)
    ]),
    _: 1
  }, 8, ["color", "disabled", "class"]))
}
}

})