import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0f03bc2f")
const _hoisted_1 = { class: "summary-table" }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = { class: "p-2" }
const _hoisted_4 = { class: "summary-item-row" }
const _hoisted_5 = { class: "summary-item-row" }
const _hoisted_6 = { class: "summary-item-row" }
_popScopeId()

import { format } from 'date-fns';

import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useVeBal from '@/composables/useVeBAL';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

/**
 * TYPES
 */
type Props = {
  fiatTotalLpTokens: string;
  veBalLockInfo: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    fiatTotalLpTokens: { type: String, required: true },
    veBalLockInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  fiatTotalLpTokens: string;
  veBalLockInfo: VeBalLockInfo;
}) {



/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { veBalTokenInfo } = useVeBal();

/**
 * COMPUTED
 */

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('unlockVeBAL.previewModal.summary.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('unlockVeBAL.previewModal.summary.totalToUnlock')), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(__props.fiatTotalLpTokens, _unref(FNumFormats).fiat)), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('unlockVeBAL.previewModal.summary.totalVotingEscrow')), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(0, _unref(FNumFormats).token)) + " " + _toDisplayString(_unref(veBalTokenInfo).symbol), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('unlockVeBAL.previewModal.summary.expiredOn')), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(format)(__props.veBalLockInfo.lockedEndDate, _unref(PRETTY_DATE_FORMAT))), 1)
      ])
    ])
  ]))
}
}

})